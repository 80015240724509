import React, { FC, useState, useCallback, useMemo } from 'react';
import YouTube from 'react-youtube';

import GatsbyImage from 'components/common/GatsbyImage';
import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';

import { getId, sToTime } from './helpers';
import { IVideoPlayerProperties } from './model';
import './VideoPlayer.scss';

const VideoPlayer: FC<IVideoPlayerProperties> = ({
  link,
  preTitle = '',
  timeNames,
  onlyVideo,
  videoPreviewPoster,
  videoPreviewPosterAlt,
  playButtonAria,
  disableFacade = false,
}) => {
  const [title, setTitle] = useState<string>('');
  const [duration, setDuration] = useState<number>(0);
  const [showVideo, setShowVideo] = useState(false);

  const videoId = useMemo(() => (link ? getId(link) : ''), [link]);
  const durationString = useMemo(
    () => (duration && timeNames ? sToTime(duration, timeNames) : ''),
    [duration]
  );
  const isYoutube = link?.includes('youtube');

  const setVideoData = useCallback((data) => {
    setDuration(data.target.getDuration());
    setTitle(data.target.getVideoData().title);
  }, []);

  const showVideoClickHandler = (e) => {
    e.stopPropagation();
    setShowVideo(true);
  };

  const isVideoShow = disableFacade || showVideo || !videoPreviewPoster;
  const autoPlayParam = videoPreviewPoster ? '?autoplay=1' : '';

  return (
    <div className="video-player">
      <div className="embed-responsive embed-responsive-16by9">
        {link && isVideoShow ? (
          <>
            {isYoutube ? (
              onlyVideo ? (
                <iframe
                  loading="lazy"
                  width="640"
                  height="360"
                  src={`https://www.youtube.com/embed/${videoId}${autoPlayParam}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : (
                <YouTube
                  className="video-player__youtube"
                  videoId={videoId}
                  onReady={setVideoData}
                />
              )
            ) : (
              <iframe
                loading="lazy"
                title="video frame"
                src={`https://player.vimeo.com/video/${link}${autoPlayParam}`}
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            )}
          </>
        ) : (
          <div className="video-player__facade" role="presentation" onClick={showVideoClickHandler}>
            <Button ariaLabel={playButtonAria} classes="video-player__facade-btn" variant="link">
              <IconCustom icon="play-circle" />
            </Button>
            {videoPreviewPoster ? (
              <GatsbyImage
                fluid={videoPreviewPoster}
                alt={videoPreviewPosterAlt}
                style={{ position: 'absolute' }}
              />
            ) : null}
          </div>
        )}
      </div>
      {onlyVideo ? null : (
        <>
          <p className="video-player__name">{`${preTitle} ${title}`}</p>
          <p className="video-player__time">{durationString}</p>
        </>
      )}
    </div>
  );
};
export default VideoPlayer;
